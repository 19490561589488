import { Fragment, LegacyRef, forwardRef, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';

import { clsx } from 'clsx';

import { InlineSkeleton } from 'components/skeleton';

import { useInViewport } from './hooks/useInViewport';
import { useLazyImage } from './hooks/useLazyImage';

import './lazy-image.styles.css';

interface IProps extends LazyLoadImageProps {
  preview?: boolean;
  size?: '1x1' | '4x3' | '16x9' | '21x9';
  text?: string;
  showShadow?: boolean;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

const LazyImage = ({
  preview = true,
  size = '1x1',
  objectFit = 'cover',
  src,
  className,
  style,
  showShadow,
  text,
  ...rest
}: IProps) => {
  const { isInViewport, ref } = useInViewport();
  const { loading, image, setError } = useLazyImage(src, isInViewport);
  const [lightbox, setLightBox] = useState(false);

  const Placeholder = <LoadingPlaceholder className={className} size={size} style={style} ref={ref} />;

  const toggleLightbox = () => {
    if (image && preview) setLightBox(!lightbox);
  };

  useEffect(() => {
    if (lightbox) {
      document.body.classList.add('lazyImage-overflow-hidden');
    } else {
      document.body.classList.remove('lazyImage-overflow-hidden');
    }
  }, [lightbox]);

  if (loading) {
    return Placeholder;
  }

  return (
    <Fragment>
      <div className={clsx('position-relative overflow-hidden', className)} style={style} onClick={toggleLightbox}>
        <LazyLoadImage
          {...rest}
          src={image}
          effect={'blur'}
          onError={() => setError(true)}
          onLoad={() => setError(false)}
          placeholder={Placeholder}
          wrapperClassName={`ratio ratio-${size} d-block`}
          style={{ objectFit }}
        />
        {showShadow && <div className="bg-linear-shadow" />}
        {text && (
          <p className="p-3 fw-bold text-capitalize position-absolute bottom-0 left-0 text-white m-0 fs-5">{text}</p>
        )}
      </div>

      {image &&
        lightbox &&
        createPortal(
          <div className={clsx('lightbox-preview', lightbox ? 'open' : '')} onClick={toggleLightbox}>
            <div className="inner">
              <Image fluid src={image} style={{ ...style }} />
            </div>
          </div>,
          document.body
        )}
    </Fragment>
  );
};

interface IPlaceholderProps {
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  size?: '1x1' | '4x3' | '16x9' | '21x9';
}

const LoadingPlaceholder = forwardRef(
  ({ className, style, size }: IPlaceholderProps, ref: LegacyRef<HTMLDivElement> | undefined) => {
    return (
      <div className={clsx('overflow-hidden', className)} style={style} ref={ref}>
        <InlineSkeleton bg="placeholder" animation="wave" className={clsx(`ratio ratio-${size} d-block`)} />
      </div>
    );
  }
);

LoadingPlaceholder.displayName = 'LoadingPlaceholder';

export default LazyImage;

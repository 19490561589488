import { useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';

import { clsx } from 'clsx';
import { FormikValues, useFormik } from 'formik';
import { yupFilterInput } from 'validations/base';
import * as Yup from 'yup';

import useResponse from 'services/api/hooks/useResponse';
import { useGetPropertyByIdQuery } from 'services/api/properties';
import { BaseQueryError } from 'services/api/types/rtk-query';
import { useGetUnitTypeByIdQuery } from 'services/api/unit-types';
import { useAddUnitPhotoMutation, useCreateUnitMutation, useUpdateUnitMutation } from 'services/api/units';

import { Dropzone } from 'components/dropzone';
import { Popup } from 'components/popup';

import { FilterPaginateInput } from 'core-ui/custom-select';
import { DeleteBtn } from 'core-ui/delete-btn';
import { LazyImage } from 'core-ui/lazy-image';
import { ProviderHOC } from 'core-ui/redux-provider/provider-hoc';
import { SwalExtended } from 'core-ui/sweet-alert';
import { Notify } from 'core-ui/toast';

import { usePhoto } from 'hooks/usePhoto';
import { useUploader } from 'hooks/useUploader';

import { FILE_TYPES_IMAGES } from 'constants/file-types';
import { getIDFromObject, getReadableError, getSearchFilter, getValidID, renderFormError } from 'utils/functions';

import { IFileInfo } from 'interfaces/IAttachments';
import { IPropertyAPI } from 'interfaces/IProperties';
import { IPhotoUnitID, ISingleUnit, IUnitTypeAPI, IUnitsAPI } from 'interfaces/IUnits';

interface IProps {
  unit?: ISingleUnit;
  update?: boolean;
  property?: string | number;
}

const UnitSchema = Yup.object().shape({
  property_name: yupFilterInput.required('this filed is required!'),
  unit_type: yupFilterInput.required('this filed is required!'),
  name: Yup.string().trim().required('This field is required!'),
  address: Yup.string().trim().min(5),
  image_preview: Yup.boolean().default(false),
  file: Yup.mixed()
    .when('image_preview', {
      is: false,
      then: schema => schema.required('this filed is required!'),
    })
    .nullable(),
});

const UnitsModal = ({ unit, update = false, property }: IProps) => {
  // add  property photo
  const [addUnitPhoto, { isError: isAddUnitPhotoError, error: addUnitPhotoError }] = useAddUnitPhotoMutation();

  useResponse({
    successTitle: 'Your photo has been uploaded!',
    isError: isAddUnitPhotoError,
    error: addUnitPhotoError,
  });

  const cover = useMemo(() => {
    if (unit && unit.cover_picture_id && unit.cover_picture) {
      return {
        id: unit.cover_picture_id,
        image: unit.cover_picture,
      };
    }
  }, [unit]);

  const { preview, hasImage, updatePreview } = usePhoto(cover);
  const {
    setSelectedFiles,
    selectedFiles,
    setTotalFiles,
    handleUpload,
    totalFiles,
    totalUploadProgress,
    totalFilesUpload,
    progress,
    filesData,
  } = useUploader('units');

  const {
    data: unit_type_data,
    isLoading: unitTypeLoading,
    isFetching: unitTypeFetching,
  } = useGetUnitTypeByIdQuery(getIDFromObject('unit_type', unit));

  // create Unit
  const [createUnit, { isSuccess: isCreateUnitSuccess, isError: isCreateUnitError, error: UnitError }] =
    useCreateUnitMutation();

  useResponse({
    isSuccess: isCreateUnitSuccess,
    successTitle: 'New unit has been added',
    isError: isCreateUnitError,
    error: UnitError,
  });

  // update Unit
  const [updateUnit, { isSuccess: isUpdateUnitSuccess, isError: isUpdateUnitError, error: UnitUpdateError }] =
    useUpdateUnitMutation();

  useResponse({
    isSuccess: isUpdateUnitSuccess,
    successTitle: 'Unit information has been successfully updated!',
    isError: isUpdateUnitError,
    error: UnitUpdateError,
  });

  const {
    data: property_data,
    isLoading: propertyLoading,
    isFetching: propertyFetching,
  } = useGetPropertyByIdQuery(getValidID(property));

  const handleFormSubmission = async (values: FormikValues) => {
    let unit_type_id = 0;
    if (values.unit_type && values.unit_type.length > 0) {
      unit_type_id = Number((values.unit_type as Array<IUnitTypeAPI>)[0].id);
    }

    let property_id = 0;
    if (property && Number(property) > 0) property_id = Number(property);
    if (values.property_name && Array.isArray(values.property_name) && values.property_name.length > 0) {
      property_id = Number((values.property_name[0] as IPropertyAPI).id);
    }

    if (!isNaN(Number(unit_type_id)) && Number(unit_type_id) > 0) {
      const data: Partial<IUnitsAPI> = {
        ...values,
        parent_property: property_id,
        unit_type: unit_type_id,
      };

      if (updateUnit && update && unit && unit.id) {
        return await updateUnit({ ...data, id: unit.id });
      } else {
        return await createUnit(data as IUnitsAPI);
      }
    }

    return Promise.reject('Incomplete information provided!');
  };

  const handleAttachments = async (files: File[], unit_id: number) => {
    let attachments: Array<IPhotoUnitID> = [];
    if (files && files.length > 0) {
      const promises: Array<Promise<IFileInfo>> = [];
      (files as File[]).map(file => promises.push(handleUpload(file)));
      await Promise.all(promises).then(results => {
        attachments = results.map((result, inx) => ({
          unit: unit_id,
          image: result.unique_name,
          is_cover: inx === 0,
        }));
      });
    }

    const promises: Array<Promise<unknown>> = [];
    if (attachments.length > 0 && addUnitPhoto) {
      attachments.map(attachment => promises.push(addUnitPhoto(attachment)));
    }

    return await Promise.all(promises);
  };

  const formik = useFormik({
    initialValues: {
      property_name: property_data ? [property_data] : ([] as Option[]),
      unit_type: unit_type_data ? [unit_type_data] : ([] as Option[]),
      name: unit?.name ?? '',
      address: unit?.address ?? '',
      image_preview: hasImage,
      file: null,
    },
    validationSchema: UnitSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      SwalExtended.showLoading();

      let unit_id = unit ? Number(unit.id) : -1;
      let property_id = property ? property : unit ? Number(unit.parent_property) : -1;
      handleFormSubmission(values)
        .then(result => {
          if (result.data) {
            unit_id = Number(result.data.id);
            property_id = Number(result.data.parent_property);
            return handleAttachments(selectedFiles, unit_id);
          } else {
            const error = result.error as BaseQueryError;
            if (error.status === 400 && error.data) {
              renderFormError(error.data, setFieldError);
            }
          }
        })
        .then(response => {
          if (response) {
            SwalExtended.close({ isConfirmed: true, value: { property: property_id, unit: unit_id } });
          }
        })
        .catch(error => {
          Notify.show({
            type: 'danger',
            title: 'Something went wrong, please check your input record',
            description: getReadableError(error),
          });
        })
        .finally(() => {
          setSubmitting(false);
          SwalExtended.hideLoading();
        });
    },
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting,
    handleReset,
    handleBlur,
    errors,
  } = formik;

  const onDrop = (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const target = e.target;
        if (target && target.result) {
          updatePreview(target.result.toString());
        }
      };

      reader.readAsDataURL(file);

      setSelectedFiles([file]);
      setFieldValue('file', [file]);
      setTotalFiles(acceptedFiles.length);
    }
  };

  const handleImageRemove = () => {
    setSelectedFiles([]);
    setFieldValue('file', null);
    setFieldValue('image_preview', false);
    updatePreview(undefined);
  };

  const currentProgress = progress.find(p => filesData.find(f => f.unique_name === p.file_id));
  return (
    <Popup
      title={'Add Units'}
      subtitle={'Add basic Unit information here'}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onReset={handleReset}
      progress={{
        uploaded: totalFilesUpload,
        progress: currentProgress && currentProgress.progress ? currentProgress.progress : 0,
        total: totalFiles,
        show: Boolean(selectedFiles.length > 0),
        totalProgress: totalUploadProgress,
      }}
    >
      <Row className="gy-md-0 gy-3 gx-md-4 gx-sm-1 gx-0">
        <Col xxl={5} xl={4} md={6}>
          {(values.file && !values.image_preview) || (values.image_preview && typeof preview === 'string') ? (
            <div className={'rounded-1 border border-dark overflow-hidden position-relative'}>
              <DeleteBtn
                resetCSS
                className="position-absolute rounded-circle bg-white end-0 m-3"
                style={{ zIndex: 1250 }}
                onClick={handleImageRemove}
              />
              <LazyImage src={preview} size="4x3" />
            </div>
          ) : (
            <Form.Group controlId="UnitFormImage">
              <div className="ratio ratio-4x3">
                <Dropzone
                  onDrop={onDrop}
                  accept={FILE_TYPES_IMAGES}
                  name="file"
                  onError={error => setFieldError('file', error.message)}
                  maxSize={5242880}
                  multiple={false}
                  maxFiles={1}
                />
              </div>
            </Form.Group>
          )}
          <Form.Control.Feedback type="invalid" className={clsx({ 'd-block': errors.file })}>
            {errors.file}
          </Form.Control.Feedback>
        </Col>
        <Col xxl={7} xl={8} md={6}>
          <div className="text-start">
            <FilterPaginateInput
              name="property_name"
              model_label="property.Property"
              labelText="Select Property"
              controlId={`UnitsFormProperty`}
              placeholder={`Select`}
              classNames={{
                labelClass: 'popup-form-labels',
                wrapperClass: 'mb-3',
              }}
              selected={values.property_name}
              onSelectChange={selected => {
                if (selected.length) {
                  setFieldValue('property_name', selected);
                } else {
                  setFieldValue('property_name', []);
                }

                setFieldValue('unit_type', []);
              }}
              labelKey={'name'}
              onBlurChange={() => setFieldTouched('property_name', true)}
              isValid={touched.property_name && !errors.property_name}
              isInvalid={touched.property_name && !!errors.property_name}
              disabled={propertyLoading || propertyFetching || Number(property) > 0}
              error={errors.property_name}
            />
            <Form.Group className="mb-4" controlId="UnitFormName">
              <Form.Label className="popup-form-labels">Unit name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter unit name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <FilterPaginateInput
              name="unit_type"
              labelText="Search Unit Type"
              model_label="property.UnitType"
              filter={getSearchFilter(values.property_name, 'parent_property')}
              controlId={`FixedAssetImportFormUnit`}
              placeholder={`Select Unit`}
              classNames={{
                labelClass: 'popup-form-labels',
                wrapperClass: 'mb-3',
              }}
              selected={values.unit_type}
              labelKey={'name'}
              onSelectChange={selected => {
                if (selected.length) {
                  setFieldValue('unit_type', selected);
                } else {
                  setFieldValue('unit_type', []);
                }
              }}
              onBlurChange={() => setFieldTouched('unit_type', true)}
              isValid={touched.unit_type && !errors.unit_type}
              isInvalid={touched.unit_type && !!errors.unit_type}
              preload={getSearchFilter(values.property_name, 'parent_property', true)}
              disabled={values.property_name.length <= 0 || unitTypeFetching || unitTypeLoading}
              error={errors.unit_type}
            />
            <Form.Group controlId="UnitFormAddress">
              <Form.Label className="popup-form-labels">Floor Address</Form.Label>
              <Form.Control
                placeholder="Enter Unit address"
                as="textarea"
                rows={5}
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.address && !errors.address}
                isInvalid={touched.address && !!errors.address}
              />
              <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </Col>
      </Row>
    </Popup>
  );
};

export default ProviderHOC(UnitsModal);

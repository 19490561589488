import './loading.css';

const LoadingDots = () => {
  return (
    <div className="container">
      <div className="stage">
        <div className="dot-flashing"></div>
      </div>
    </div>
  );
};

export default LoadingDots;

import { isValidElement, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { clsx } from 'clsx';

import { getDate } from 'utils/functions';

import { DateRangeChange, IRangeDateInput } from './date';
import DateWrapper from './date-wrapper';

const DateRangeInput = (props: IRangeDateInput) => {
  const {
    onDateRangeSelection,
    controlId,
    resetDate,
    placeholder = 'YYYY-MM-DD - YYYY-MM-DD',
    labelText,
    error,
    classNames = { labelClass: '', wrapperClass: '' },
    startDateValue,
    endDateValue,
    ...rest
  } = props;

  const [dateRange, setDateRange] = useState([initValue(startDateValue), initValue(endDateValue)]);
  const [startDate, endDate] = dateRange;

  const onChangeEvent = (date: DateRangeChange) => {
    const start = date[0] ? getFullDate(date[0]) : '';
    const end = date[1] ? getFullDate(date[1]) : '';

    const dateSelected = start !== '' && end !== '';
    if ((!date || !dateSelected) && onDateRangeSelection) onDateRangeSelection('', '');
    if (onDateRangeSelection && dateSelected) {
      onDateRangeSelection(start, end);
    }

    setDateRange(date);
  };

  useEffect(() => {
    if (resetDate) {
      setDateRange([initValue(), initValue()]);
    }
  }, [resetDate]);

  return (
    <Form.Group className={classNames?.wrapperClass} controlId={controlId}>
      {labelText && <Form.Label className={clsx('text-primary', classNames?.labelClass)}>{labelText}</Form.Label>}
      <DateWrapper
        {...rest}
        controlId={controlId}
        placeholderText={placeholder}
        enableRangeSelection
        onDateSelection={date => onChangeEvent(date as DateRangeChange)}
        startDate={startDate}
        selected={startDate}
        endDate={endDate}
        showTwoColumnMonthYearPicker
      />
      {error &&
        (isValidElement(error) ? (
          error
        ) : (
          <Form.Control.Feedback type="invalid" className={clsx({ 'd-block': props.isInvalid && error })}>
            {Array.isArray(error) ? error.join(' \u2022 ') : error.toString()}
          </Form.Control.Feedback>
        ))}
    </Form.Group>
  );
};

const initValue = (value?: string | number | string[]) => {
  const date = value && getDate(value.toString());
  if (!date || date === '-') return null;
  return new Date(value.toString());
};

const getFullDate = (date: Date) => {
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export default DateRangeInput;

import { isValidElement, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { clsx } from 'clsx';

import { getDate } from 'utils/functions';

import { DateChange, ISingleDateInput } from './date';
import DateWrapper from './date-wrapper';

const InputDate = (props: ISingleDateInput) => {
  const {
    resetDate,
    onDateSelection,
    controlId,
    placeholder = 'YYYY-MM-DD',
    labelText,
    error,
    classNames = { labelClass: '', wrapperClass: '' },
    value,
    ...rest
  } = props;

  const [startDate, setStartDate] = useState<DateChange>(initValue(value));
  const onChangeEvent = (date: DateChange) => {
    if (!date && onDateSelection) onDateSelection('');
    if (onDateSelection && date) {
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const year = date.getFullYear();

      const fullDate = `${year}-${month}-${day}`;
      onDateSelection(fullDate);
    }
    setStartDate(date);
  };

  useEffect(() => {
    if (resetDate) {
      setStartDate(initValue());
    }
  }, [resetDate]);

  return (
    <Form.Group className={classNames.wrapperClass} controlId={controlId}>
      {labelText && <Form.Label className={clsx('text-primary', classNames.labelClass)}>{labelText}</Form.Label>}
      <DateWrapper
        {...rest}
        controlId={controlId}
        placeholderText={placeholder}
        onDateSelection={date => onChangeEvent(date as DateChange)}
        selected={startDate}
      />
      {error &&
        (isValidElement(error) ? (
          error
        ) : (
          <Form.Control.Feedback type="invalid" className={clsx({ 'd-block': props.isInvalid && error })}>
            {Array.isArray(error) ? error.join(' \u2022 ') : error.toString()}
          </Form.Control.Feedback>
        ))}
    </Form.Group>
  );
};

const initValue = (value?: string | number | string[]) => {
  const date = value && getDate(value.toString());
  if (!date || date === '-') return null;
  return new Date(value.toString());
};

export default InputDate;

export const PERMISSIONS = {
  ADMIN: 'ADMIN',
  TENANT: 'TENANT',
  PEOPLE: 'PEOPLE,ADMIN',
  LEASING: 'LEASING,ADMIN',
  PROPERTY: 'PROPERTY,ADMIN',
  ACCOUNTS: 'ACCOUNTS,ADMIN',
  MAINTENANCE: 'MAINTENANCE,ADMIN',
  COMMUNICATION: 'COMMUNICATION,ADMIN',
  SYSTEM_PREFERENCES: 'SYSTEM_PREFERENCES,ADMIN',
  GENERAL_DASHBOARD: 'MAINTENANCE,LEASING,COMMUNICATION,PROPERTY,ADMIN',
};

import InformationSkeleton from './placeholder-information';
import PropertySkeleton, { SkeletonInlineProperty } from './placeholder-property';
import Skeleton, { ButtonSkeleton, DescriptionSkeleton, ImageSkeleton, InlineSkeleton } from './skeleton';

export {
  InlineSkeleton,
  ButtonSkeleton,
  DescriptionSkeleton,
  ImageSkeleton,
  PropertySkeleton,
  InformationSkeleton,
  SkeletonInlineProperty,
};
export default Skeleton;

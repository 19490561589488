import { useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { clsx } from 'clsx';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

import useResponse from 'services/api/hooks/useResponse';
import { BaseQueryError } from 'services/api/types/rtk-query';
import {
  useAddUnitTypePhotoMutation,
  useCreateUnitTypeMutation,
  useUpdateUnitTypeMutation,
} from 'services/api/unit-types';

import { Dropzone } from 'components/dropzone';
import { Popup } from 'components/popup';

import { DeleteBtn } from 'core-ui/delete-btn';
import { LazyImage } from 'core-ui/lazy-image';
import { ProviderHOC } from 'core-ui/redux-provider/provider-hoc';
import { SwalExtended } from 'core-ui/sweet-alert';
import { Notify } from 'core-ui/toast';

import { usePhoto } from 'hooks/usePhoto';
import { useUploader } from 'hooks/useUploader';

import { FILE_TYPES_IMAGES } from 'constants/file-types';
import { getReadableError, renderFormError } from 'utils/functions';

import { IFileInfo } from 'interfaces/IAttachments';
import { IPhotoUnitTypeID, ISingleUnitType, IUnitTypeAPI } from 'interfaces/IUnits';

interface IProps {
  unitType?: ISingleUnitType;
  property?: string | number;
  update?: boolean;
}

const UnitTypeSchema = Yup.object().shape({
  name: Yup.string().trim().required('This field is required!'),
  image_preview: Yup.boolean().default(false),
  file: Yup.mixed()
    .when('image_preview', {
      is: false,
      then: schema => schema.required('this filed is required!'),
    })
    .nullable(),
});

const UnitTypesModal = ({ unitType, update = false, property }: IProps) => {
  // add  property photo
  const [addUnitTypePhoto, { isError: isAddUnitTypePhotoError, error: addUnitTypePhotoError }] =
    useAddUnitTypePhotoMutation();

  useResponse({
    successTitle: 'Your photo has been uploaded!',
    isError: isAddUnitTypePhotoError,
    error: addUnitTypePhotoError,
  });

  const cover = useMemo(() => {
    if (unitType && unitType.cover_picture_id && unitType.cover_picture) {
      return {
        id: unitType.cover_picture_id,
        image: unitType.cover_picture,
      };
    }
  }, [unitType]);

  const { preview, hasImage, updatePreview } = usePhoto(cover);
  const {
    setSelectedFiles,
    selectedFiles,
    setTotalFiles,
    handleUpload,
    totalFiles,
    totalUploadProgress,
    totalFilesUpload,
    progress,
    filesData,
  } = useUploader('unit-types');

  // create Unit
  const [createUnitType, { isSuccess: isCreateUnitTypeSuccess, isError: isCreateUnitTypeError, error: UnitTypeError }] =
    useCreateUnitTypeMutation();

  useResponse({
    isSuccess: isCreateUnitTypeSuccess,
    successTitle: 'New unit type has been added',
    isError: isCreateUnitTypeError,
    error: UnitTypeError,
  });

  // update Unit
  const [
    updateUnitType,
    { isSuccess: isUpdateUnitTypeSuccess, isError: isUpdateUnitTypeError, error: UnitUpdateTypeError },
  ] = useUpdateUnitTypeMutation();

  useResponse({
    isSuccess: isUpdateUnitTypeSuccess,
    successTitle: 'Unit information has been successfully updated!',
    isError: isUpdateUnitTypeError,
    error: UnitUpdateTypeError,
  });

  const handleFormSubmission = async (values: FormikValues) => {
    if (!isNaN(Number(property)) && Number(property) > 0) {
      const data: Partial<IUnitTypeAPI> = {
        ...values,
        parent_property: Number(property),
      };

      if (updateUnitType && update && unitType && unitType.id) {
        return await updateUnitType({ ...data, id: unitType.id });
      } else {
        return await createUnitType(data as IUnitTypeAPI);
      }
    }

    return Promise.reject('Incomplete data provided!');
  };

  const handleAttachments = async (files: File[], unit_type_id: number) => {
    let attachments: Array<IPhotoUnitTypeID> = [];
    if (files && files.length > 0) {
      const promises: Array<Promise<IFileInfo>> = [];
      (files as File[]).map(file => promises.push(handleUpload(file)));
      await Promise.all(promises).then(results => {
        attachments = results.map((result, inx) => ({
          unit_type: unit_type_id,
          image: result.unique_name,
          is_cover: inx === 0,
        }));
      });
    }

    const promises: Array<Promise<unknown>> = [];
    if (attachments.length > 0 && addUnitTypePhoto) {
      attachments.map(attachment => promises.push(addUnitTypePhoto(attachment)));
    }

    return await Promise.all(promises);
  };

  const formik = useFormik({
    initialValues: {
      name: unitType?.name ?? '',
      image_preview: hasImage,
      file: null,
    },
    validationSchema: UnitTypeSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      SwalExtended.showLoading();

      let unit_type_id = unitType ? Number(unitType.id) : -1;
      handleFormSubmission(values)
        .then(result => {
          if (result.data) {
            unit_type_id = Number(result.data.id);
            return handleAttachments(selectedFiles, unit_type_id);
          } else {
            const error = result.error as BaseQueryError;
            if (error.status === 400 && error.data) {
              renderFormError(error.data, setFieldError);
            }
          }
        })
        .then(response => {
          if (response) {
            SwalExtended.close({ isConfirmed: true, value: unit_type_id });
          }
        })
        .catch(error => {
          Notify.show({
            type: 'danger',
            title: 'Something went wrong, please check your input record',
            description: getReadableError(error),
          });
        })
        .finally(() => {
          setSubmitting(false);
          SwalExtended.hideLoading();
        });
    },
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    values,
    setFieldValue,
    setFieldError,
    isSubmitting,
    handleReset,
    handleBlur,
    errors,
  } = formik;

  const onDrop = (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const target = e.target;
        if (target && target.result) {
          updatePreview(target.result.toString());
        }
      };

      reader.readAsDataURL(file);

      setSelectedFiles([file]);
      setFieldValue('file', [file]);
      setTotalFiles(acceptedFiles.length);
    }
  };

  const handleImageRemove = async () => {
    setSelectedFiles([]);
    setFieldValue('file', null);
    setFieldValue('image_preview', false);
    updatePreview(undefined);
  };

  const currentProgress = progress.find(p => filesData.find(f => f.unique_name === p.file_id));
  return (
    <Popup
      title={`${update ? 'Update' : 'Add'} Unit Types`}
      subtitle={`${update ? 'Update' : 'Add'} general unit types information here`}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onReset={handleReset}
      progress={{
        uploaded: totalFilesUpload,
        progress: currentProgress && currentProgress.progress ? currentProgress.progress : 0,
        total: totalFiles,
        show: Boolean(selectedFiles.length > 0),
        totalProgress: totalUploadProgress,
      }}
    >
      <Row className="gy-md-0 gy-3 gx-md-4 gx-sm-1 gx-0">
        <Col xxl={6} xl={4} md={6}>
          {(values.file && !values.image_preview) || (values.image_preview && typeof preview === 'string') ? (
            <div className={'rounded-1 border border-dark overflow-hidden position-relative'}>
              <DeleteBtn
                resetCSS
                className="position-absolute rounded-circle bg-white end-0 m-3"
                style={{ zIndex: 1250 }}
                onClick={handleImageRemove}
              />
              <LazyImage src={preview} size="4x3" />
            </div>
          ) : (
            <Form.Group controlId="UnitFormTypeImage">
              <div className="ratio ratio-4x3">
                <Dropzone
                  onDrop={onDrop}
                  name="file"
                  accept={FILE_TYPES_IMAGES}
                  maxSize={5242880}
                  onError={error => setFieldError('file', error.message)}
                  multiple={false}
                  maxFiles={1}
                />
              </div>
            </Form.Group>
          )}
          <Form.Control.Feedback type="invalid" className={clsx({ 'd-block': errors.file })}>
            {errors.file}
          </Form.Control.Feedback>
        </Col>
        <Col xxl={6} xl={8} md={6}>
          <div className="text-start">
            <Form.Group className="mb-4" controlId="UnitFormTypeName">
              <Form.Label className="popup-form-labels">Unit Type Name</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                placeholder="Enter unit type name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
          </div>
        </Col>
      </Row>
    </Popup>
  );
};

export default ProviderHOC(UnitTypesModal);
